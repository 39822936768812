<template>
  <div :class="'student-problem theme-' + getTheme"
       :style="{ ...fontSelected, ...copy_paste }"
       :translate="language_translate"
  >
    <note-floating-button :problem="problem" />
    <b-overlay
      :show="isProcessing"
      variant="transparent"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
        </div>
      </template>
      <b-card
        class="mb-0 problem-card"
        body-class="p-0"
      >
        <Header
          v-if="!result && !teachersStudentId"
          :active-problem-id="problem.id"
          :problem-name="problem.name"
          :progress="progress"
          :show-progress="!result"
          :total-passed="totalPassed"
          :lesson-name="problem.lesson"
          :lesson-pdf="problem.lesson_pdf"
          :lesson-group-name="lessonGroup"
          :is-progress-exception="isProgressException"
          :engine="engine"
          :percentage="percentage"
          :round="round"
          :page-link="problem.page_link"
          :score-data="scoreData"
          :passed-problems="passedProblems"
          :level="Number(level)"
          :loader-id="loaderId"
          :skill-type="skillType"
          :progress-info="progressInfo"
          :has-minimal-view="hasMinimalView"
          :lesson-time-spent="lessonTimeSpent"
          :total-test-time="totalTestTime"
          :school-settings="schoolSettings"
          :district-settings="districtSettings"
          :lesson-mode="lessonMode"
          @nextProblem="getProblem"
        />
        <div
          v-if="!isProcessing && hasErrorInProblem && (!problem || Object.keys(problem).length === 0 )"
          style="
            background-image: url('/edu/images/no-problems.jpg');
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          "
          class="text-center row"
        >
          <div
            style="position: absolute; top: 45%;left: 10%;font-size: 1.5vw"
            class="text-center text-white"
          >
            {{ $t('student-lab-module.practice-materials-not-publised') }}<br>
            {{ $t('student-lab-module.contact-administrator') }}
          </div>
        </div>

        <template v-if="!result && !teachersStudentId">
          <!-- carryforward engine -->
          <template
            v-if="
              engine === 'carryforwardengine' ||
                engine === 'doublefallbackengine'
            "
          >
            <carry-forward-statement
              v-if="isStatement"
              :problem-statements="problemStatements"
              :level="level"
              :problem="problem"
              @nextProblem="getProblem({ continue: true })"
            />
            <problem
              v-else
              :problem="problem"
              :pid="pid"
              :user-input="userInput"
              :level="level"
              :has-statement="false"
              :engine="engine"
              :loader-id="loaderId"
              :has-minimal-view="hasMinimalView"
              :is-class-test="isClassTest"
              :school-settings="schoolSettings"
              :district-settings="districtSettings"
              @onRefetch="level => getProblem({ lang_level: level })"
              @nextProblem="getProblem"
            />
          </template>

          <!-- adaptive engine / linear standalone engine -->
          <template
            v-else-if="
              engine === 'adaptivepathengine' ||
                engine === 'linearstandaloneengine'
            "
          >
            <carry-forward-statement
              v-if="isStatement"
              :problem-statements="problemStatements"
              :level="level"
              :problem="problem"
              @nextProblem="getProblem({ continue: true })"
            />
            <problem
              v-else
              :problem="problem"
              :has-statement="problem && !problem.rules?.hide_statement && !isSdpTest && !isClassTest"
              :engine="engine"
              :level="level"
              :is-sdp-test="isSdpTest"
              :pid="pid"
              :user-input="userInput"
              :loader-id="loaderId"
              :has-minimal-view="hasMinimalView"
              :is-class-test="isClassTest"
              :school-settings="schoolSettings"
              :district-settings="districtSettings"
              @onRefetch="level => getProblem({ lang_level: level })"
              @updateStar="updateStart"
              @nextProblem="getProblem"
              @playLastPart="playLastPart"
              @getHighScore="getHighScore"
            />
          </template>
        </template>

        <result
          v-else
          :teachers-student-id="teachersStudentId"
          :result-info="result || {}"
        />
        <audio
          ref="successAudio"
          src="https://assets.mixkit.co/sfx/preview/mixkit-casting-long-fairy-magic-spell-875.mp3"
        />
      </b-card>
    </b-overlay>

    <!-- Issue Report -->
    <issue-report
      v-if="isASuperAdmin || canReportIssue"
      class="issue-report"
      :lesson-id="lessonId"
      :problem-id="problem && problem.id"
      :user-id="self.id"
    />

    <game-selector
      :show="hasRandomGames && !hasGamePlayedAlready() && !hideGame"
      :loader-id="loaderId"
      @close="hasRandomGames = false"
    />
    <set-picker
      v-if="!!setsData"
      :lesson-id="lessonId"
      :class-id="classId"
      :sets-data="setsData"
      @close="setsData = null"
      @getProblem="getProblem"
    />
    <student-chat-bot v-if="!!pid && hasTeacherChatBot && !autoPlay && !hasMinimalView"
                      :lesson-info="problem?.lesson_info"
                      :problem="problem"
    />
  </div>
</template>

<script>
import { getIdFromURL } from 'vue-youtube-embed'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_TEACHER,
  USER_TYPE_SUPER,
  USER_TYPE_STUDENT,
} from '@/const/userType'
import {
  BCard, BOverlay, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'
import SkillTypes from '@/const/skillTypes'
import { LOADER_TYPE_SDP_TEST } from '@/const/loaderType'
import StudentChatBot from '@/views/student/chatbot/index.vue'
import { checkSettings, isVisible } from '@/utils/visibilitySettings'
import Header from './compoments/Header.vue'
import CarryForwardStatement from './CarryForwardStatement.vue'
import Problem from './Problem.vue'
import Result from './compoments/Result.vue'
import NoteFloatingButton from './compoments/NoteFloatingButton.vue'
import GameSelector from './compoments/GameSelector.vue'
import SetPicker from './compoments/set-picker/index.vue'
import IssueReport from './compoments/IssueReport.vue'

export default {
  components: {
    BCard,
    BOverlay,
    Header,
    CarryForwardStatement,
    Problem,
    Result,
    NoteFloatingButton,
    BSpinner,
    GameSelector,
    SetPicker,
    IssueReport,
    StudentChatBot,
  },
  data() {
    return {
      self: getUserData(),
      progress: {
        current: 0,
        total: 0,
      },
      lessonId: null,
      classId: null,
      pid: 0,
      isStatement: false,
      problem: {},
      problemStatements: [],
      result: null,
      isProgressException: false,
      passedProblems: [],
      engine: '',
      level: 0,
      totalPassed: 0,
      percentage: 0,
      round: 0,
      highestScoreData: null,
      scoreData: null,
      teachersStudentId: null,
      isProcessing: false,
      hasErrorInProblem: false,
      loaderId: null,
      hasRandomGames: false,
      isGameLoaded: false,
      setsData: null,
      isSdpTest: false,
      currentLanguage: this.$i18n.locale,
      progressInfo: [],
      userInput: [],
      lessonTimeSpent: 0,
      totalTestTime: null,
      hasMinimalView: false,
      isClassTest: false,
      schoolSettings: [],
      districtSettings: [],
      lessonMode: 'normal',
      lessonGroup: null,
      enableChatBot: false,
    }
  },
  computed: {
    hasTeacherChatBot() {
      return this.$store.state.session.settings.teacherChatBot && this.enableChatBot
    },
    skillType() {
      if (this.problem.skill_type) {
        return SkillTypes.find(type => type.value === this.problem.skill_type)?.text
      }
      return ''
    },
    getTheme() {
      const animatedConfig = this.$store.state.problem.animatedLayoutConfig

      if (animatedConfig && animatedConfig.bodyTheme) {
        return animatedConfig.bodyTheme
      }
      return (this.problem && this.problem.theme) ? this.problem.theme : '0'
    },

    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isASuperAdmin() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    autoPlay() {
      return this.$route.query.autoPlay
    },
    canReportIssue() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return isVisible('report_issue', schoolSetting, distSetting)
    },
    fontSelected() {
      if (this.self.font) {
        return {
          'font-family': this.self.font.value,
        }
      }
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const font = checkSettings('font', schoolSetting, distSetting, { schoolOverride: true })
      return {
        'font-family': font?.value,
      }
    },
    language_translate() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('language_translate', schoolSetting, distSetting)
      return setting.value === '1' ? 'yes' : 'no'
    },
    copy_paste() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const setting = checkSettings('copy_paste', schoolSetting, distSetting)
      return setting.value === '1' ? {} : { 'user-select': 'none' }
    },
    hideGame() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('game', schoolSetting, distSetting)
    },
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        this.lessonId = parseInt(id, 10)
      },
      deep: true,
      immediate: true,
    },
    '$route.params.classId': {
      handler(id) {
        this.classId = parseInt(id, 10)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getProblem()
    if (this.$route.query.student_id) {
      this.teachersStudentId = Number(this.$route.query.student_id) // we need student id to show result direct to teacher this is user only in class result for completed course & only teacher can do so
    }
    this.getHighScore()
    this.$store.dispatch('app/getLanguageLevelByCourse', {
      lesson_id: this.$route.params.id,
    })
    this.getClassRoomSettings()
  },
  beforeDestroy() {
    this.$store.commit('app/SET_LANGUAGE_LEVEL_INDICATOR')
    // this.setLanguage(this.currentLanguage)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    setLanguage(locale) {
      // if (locale) {
      //   this.$i18n.locale = locale
      // }
    },
    hasGamePlayedAlready() {
      return !!localStorage.getItem(this.getStoreKeyForPlayedGame())
    },
    updateStart() {
      this.totalPassed += 1
    },
    getHighScore() {
      useApollo.getLessonHighScore({
        lessonId: this.lessonId,
        roomId: this.classId,
      }).then(response => {
        const scores = response.data.sorting
        if (scores.length > 0) {
          this.highestScoreData = { ...scores[0] }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkSetAndGetProblem(param = {}) {
      useJwt.getStudentProblemSets({
        params: {
          lesson_id: this.lessonId,
          class_id: this.classId,
        },
      }).then(response => {
        const { data } = response.data
        if (data.has_sets) {
          this.setsData = data
        } else {
          this.setData = null
          this.getProblem(param)
        }
      }).catch(error => {
        this.getProblem(param)
        this.showErrorMessage(error)
      })
    },
    getProblem(param = {}) {
      // reset statement and problem object

      this.isProcessing = true
      if (this.autoPlay) {
        // eslint-disable-next-line no-param-reassign
        param.autoPlay = 1
        // eslint-disable-next-line no-param-reassign
        param.option = this.$route.query.option
      }
      useJwt
        .getProblemForLesson(this.lessonId, {
          params: {
            cid: this.classId,
            ...param,
            testId: this.$route.query.testId,
            type: this.$route.query.type,
            eventId: this.$route.query.event_id,
          },
        })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          this.problem = {}
          this.problemStatements = []
          try {
            const setData = await useJwt.getStudentProblemSets({
              params: {
                lesson_id: this.lessonId,
                class_id: this.classId,
              },
            })
            const { data } = setData.data
            if (data.has_sets) {
              this.setsData = data
              return null
            }
          } catch (error) {
            console.error(error)
          }
          // normalize response
          const res = response?.data?.data
          this.engine = res?.engine?.toLowerCase()
          this.level = res?.loaderType === LOADER_TYPE_SDP_TEST ? res?.level : (res?.data?.[0]?.lang_level || res?.level)
          this.totalPassed = res?.totalPassed
          this.percentage = res?.percentage
          this.lessonGroup = res?.lesson_group
          this.round = res?.round
          this.scoreData = res?.report
          this.loaderId = res?.loaderId
          this.isSdpTest = res?.loaderType === LOADER_TYPE_SDP_TEST
          this.progressInfo = res.progress_info
          this.userInput = res.user_input
          this.hasMinimalView = res.hasMinimalView
          this.hasRandomGames = res?.hasGame
          this.lessonTimeSpent = res?.lesson_time_spent
          this.totalTestTime = res?.testTime || 0
          this.isClassTest = res?.isClassTest || false
          this.lessonMode = res?.lesson_mode
          this.enableChatBot = res?.enable_chat_bot || false
          if (this.engine === 'adaptivepathengine' || this.engine === 'linearstandaloneengine') this.handleAdaptiveResponse(res)
          else if (this.engine === 'carryforwardengine' || this.engine === 'doublefallbackengine') this.handleCarryForwardResponse(res)
          // handle result
          else if (res?.status === 'passed' || res?.status === 'failed' || res?.result) {
            this.result = this.processResult(res)
            if (this.$refs.successAudio) {
              this.$refs.successAudio.volume = 0.05
              this.$refs.successAudio.play()
            }
          }
          this.getHighScore()
          this.progress = { ...res?.progress }
          this.passedProblems = res.passedProblems
          this.pid = res?.pid
          if (this.problem.type !== 'hotspot' && res?.status !== 'passed' && res?.status !== 'failed' && !res?.result) {
            this.$store.commit('studentNotes/SET_PROBLEM_ID', this.problem.id)
            this.$store.commit('studentNotes/SET_PROBLEM_NAME', this.problem.name)
          }
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 600)
        }).catch(error => {
          this.problem = {}
          this.problemStatements = []
          this.hasErrorInProblem = true
          console.error(error)
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    handleAdaptiveResponse(res) {
      if (res?.status === 'in_progress' || res?.status === 'continue') {
        // eslint-disable-next-line prefer-destructuring
        this.problem = res.data[0]
        this.$store.commit('problem/SET_TTS_SUPPORT', !!res.data[0]?.tts_support)
        if (this.problem?.video_time_stamp) this.problem.video = this.processVideoData(this.problem.video_time_stamp)
      } else if (res?.status === 'intervention') {
        const problemKeys = Object.keys(res.data)

        // assign response to statement object
        problemKeys.forEach(k => {
          const statement = res.data[k]
          if (res.data[k].video_time_stamp) statement.video = this.processVideoData(res.data[k].video_time_stamp)
          this.problemStatements.push(statement)
        })
        this.isStatement = true
        if (!this.problemStatements.length) {
          this.getProblem({ continue: true })
        }
      }
    },
    handleCarryForwardResponse(res) {
      // check problem status
      if (res?.status === 'in_progress' || res?.status === 'assessment' || res?.status === 'continue') {
        // assign response to problem object
        // eslint-disable-next-line prefer-destructuring
        this.problem = res.data[0]
        this.isStatement = false
      } else if (res?.status === 'intervention') {
        const problemKeys = Object.keys(res.data)

        // assign response to statement object
        problemKeys.forEach(k => {
          const statement = res.data[k]
          if (res.data[k].video_time_stamp) statement.video = this.processVideoData(res.data[k].video_time_stamp)
          this.problemStatements.push(statement)
        })
        this.isStatement = true
        if (!this.problemStatements.length) {
          this.getProblem({ continue: true })
        }
      }
    },
    processResult(data) {
      const result = data.result.reduce((r, a) => {
        const existingEntryIndex = r.findIndex(e => e.code === a.code)
        let existingEntry = {}

        if (existingEntryIndex < 0) {
          existingEntry = { ...a, ...{ total_problems: 1, passed_problems: a.status === 'pass' ? 1 : 0 } }
          r.push(existingEntry)
        } else {
          existingEntry = r[existingEntryIndex]
          existingEntry.total_problems += 1
          existingEntry.passed_problems += a.status === 'pass' ? 1 : 0
          // eslint-disable-next-line no-param-reassign
          r[existingEntryIndex] = existingEntry
        }
        return r
      }, [])
      // eslint-disable-next-line no-param-reassign
      data.result = result
      return data
    },
    processVideoData(videoTimestamp) {
      return {
        startTimeInMs: videoTimestamp?.start_time ? this.timeToMilliseconds(videoTimestamp?.start_time) : 0,
        endTimeInMs: videoTimestamp?.time ? this.timeToMilliseconds(videoTimestamp?.time) : null,
        youtubeId: getIdFromURL(videoTimestamp?.video?.url),
      }
    },
    timeToMilliseconds(duration) {
      const [minutes, seconds] = duration.split(':')
      const millisec = Number(minutes) * 60 + Number(seconds)
      return Number(millisec)
    },
    playLastPart() {
      this.problem.video_time_stamp = null
      if (this.problem.video) {
        const video = {
          startTimeInMs: this.problem?.video?.endTimeInMs,
          youtubeId: this.problem?.video?.youtubeId,
        }
        this.problem.video = video
      }
    },

    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        this.schoolSettings = res.data?.data
        this.districtSettings = res.data?.district_settings
        this.$store.commit('studentLab/SET_DISTRICT_SETTINGS', res.data?.district_settings)
      })
    },
  },
}
</script>

<style lang="scss">
@import "/src/assets/scss/custom/problem-themes/main.scss";
</style>

<style scoped>
.issue-report {
  position: fixed;
  bottom: 7%;
  left: 0;
  width: 230px;
  z-index: 10;
}
</style>
